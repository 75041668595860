import { FluidObject } from "gatsby-image";
import Img from "gatsby-image";

import { Container, Metadata } from "@/component";

import { PageProps } from "./type";
import style from "./notFound.module.scss";

export type Context = {
  image: FluidObject;
};

export default function NotFoundPage(
  props: PageProps<Record<string, unknown>, Context>
): JSX.Element {
  const { pageContext, location } = props;
  const { image } = pageContext;
  const { pathname } = location;
  return (
    <>
      <Metadata title="找不到網頁" pathname={pathname} />
      <Container className={style.container} padding>
        <h1>找不到網頁</h1>
        <Img fluid={image} className={style.image} title="找不到網頁" />
      </Container>
    </>
  );
}
